import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { SemiCircleProgress } from 'react-semicircle-progressbar';
import CircleIcon from '@mui/icons-material/Circle';
import CustomSemiCircleProgressWrapper from './CustomSemiCircleProgressWrapper.jsx';
import { useParams } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { theme } from '../../../styles/Theme';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import axios from 'axios';
import affinity_logo from '../../../assets/affinity_logo.png';


import { Box } from '@mui/material';

const classes = {
  list: {
    backgroundColor: '#D1FAE5',
    borderRadius: '10px',
    borderLeft: '5px solid #047857',
    marginTop: '15px',
    marginBottom: '15px',
  },
  list_weakness: {
    backgroundColor: '#FFE4E6',
    borderRadius: '10px',
    borderLeft: '6px solid #BE123C',
    marginTop: '15px',
    marginBottom: '0px',
  },
  results_box: {
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '30px',
    width: '100%',
    maxWidth: '560px',
    margin: '0 auto',
    overflowY: 'auto',
    
  },
  contentInside:{
    width: '100%',
    maxWidth:'435px',
  },

  closeIconStyling: {
    padding: '4px',
    borderRadius: '100px',
    backgroundColor: 'rgba(255, 0, 0, 0.2)',
    cursor: 'pointer',
    '&:hover': {
      fill: '#FF5349',
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  affinityGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    
  },
  

  affinityTextField: {
    margin: '0px 10px 0 10px', 
    flexGrow: 1,
  },
  
  affinityBtn: {
    backgroundColor: 'white',
    padding: 'none',
    borderRadius: '100%',
    border: 'transparent',
    width: '60px',
    marginTop: '5px', 
    '&:hover': {
      border: 'transparent',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      mt: '15px',
      mb: '15px',
    },
  },
};


const Result = ({ result }) => {
  const [modalTextInput, setModalTextInput] = useState('');
  const { user_id } = useParams();
  const client_id = Cookies.get('access_token');
  const [affinityError, setAffinityError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [dataSentSuccess, setDataSentSuccess] = useState(false);
  const [dataSentError, setDataSentError] = useState(false);

  const sendAffinityData = () => {
    setDataSentSuccess(false);
    setDataSentError(false);
    
    if (modalTextInput === '') {
      setAffinityError('*URL input cannot be empty');
      alert('URL input cannot be empty');
    } else {
      if (result) {
        setLoading(true);
        axios
          .post('/api/affinity', {
            url: modalTextInput,
            result: result,
          })
          .then((res) => {
            if (res.status === 200) {
        
              alert('Data sent successfully.');
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
        
            alert('Data sending failed.');
          });
      }
    }
  };

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      direction='column'
      sx={classes.results_box}
    >
      <div className={classes.contentInside}>
      <Grid item xs={12}>
        <Typography variant='h4'  align='center' sx={{ color: 'black', fontWeight: 'bold' ,marginTop:'5px' }}>
          Results
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container  
        justifyContent='center'
        
        sx={{ position: 'relative', padding: 'none', maxHeight: '275px' , marginBottom:'-30px'}}
      >
     <CustomSemiCircleProgressWrapper score={result.score}/>
       
      </Grid>
      <Grid item sx={{ width: '100%' }}>
              <Divider
          sx={{ height: '1px', backgroundColor: '#E5E7EB', width: '100%', marginTop: '10px', marginBottom: '15px' }}
        />

      </Grid>
      {result.strengths && result.strengths.length > 0 && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '100%',
              margin: '15px 0 15px 0',
            }}
          >
            <Typography
              sx={{ fontSize: '25px', fontWeight: 'bold', color: 'black' }}
            >
              Strengths
            </Typography>
            <List sx={classes.list}>
              {result.strengths.map((result, index) => {
                return (
                  <ListItem sx={{ padding: '0px 16px' }} key={index}>
                    <ListItemIcon sx={{ minWidth: '16px' }}>
                      <CircleIcon sx={{ fontSize: '8px', fill: 'black' }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          color: 'black',
                          fontWeight: '650',
                        }}
                      >
                        {result}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
          <Divider
          sx={{ height: '1px', backgroundColor: '#E5E7EB', width: '100%', marginTop: '10px', marginBottom: '15px' }}
        />
          </Grid>
        </>
      )}

      {result.weaknesses && result.weaknesses.length > 0 && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '100%',
              margin: '15px 0 0 0',
            }}
          >
            <Typography
              sx={{ fontSize: '25px', fontWeight: 'bold', color: 'black' }}
            >
              Weaknesses
            </Typography>
            <List sx={classes.list_weakness}>
              {result.weaknesses.map((result, index) => {
                return (
                  <ListItem sx={{ padding: '0px 16px' }} key={index}>
                    <ListItemIcon sx={{ minWidth: '16px' }}>
                      <CircleIcon sx={{ fontSize: '8px', fill: 'black' }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          color: 'black',
                          fontWeight: '650',
                        }}
                      >
                        {result}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
           
          </Grid>
        </>
      )}

      <Grid
        container
        alignItems='center'
        justifyContent='center'
        direction='column'
        sx={classes.results_box}
        spacing={2}
      >

<Box sx={classes.affinityGroup}>
  <TextField
    type='url'
    pattern='https?://.+'
    placeholder='Enter Affinity URL'
    value={modalTextInput}
    onChange={(e) => setModalTextInput(e.target.value)}
    inputProps={{
      style: {
        height: '13px',
      },
    }}
    sx={classes.affinityTextField}
  />
  <Button
    variant='outlined'
    sx={classes.affinityBtn}
    onClick={() => sendAffinityData()}
  >
    <img
      alt='affinity_logo'
      src={affinity_logo}
      style={{
        width:  'auto',
        height: '46px',
        cursor: 'pointer',
        marginBottom: '7px',
      }}
    />
  </Button>
</Box>

      </Grid>
      
       </div>
    </Grid>
  );
};

export default Result;
